<template>
  <div class="header">
    <el-container>
      <div class="header__content">
        <div class="header__content-logo">
          <img src="@/assets/images/logo/logo.png" alt="" />
        </div>
        <div class="header__content-action">
          <div class="action">
            <span class="action__user"> {{ translation.hello }}, {{ user.name }} </span>
            <div class="action__menu" v-click-outside="setIsActionMenuBoxUserVisibleFalse">
              <a href="#" class="action__menu-icon" @click.prevent="isActionMenuBoxUserVisible = true">
                <User />
              </a>
              <div class="action__menu-box" :class="{ 'action__menu-box--is-visible': isActionMenuBoxUserVisible }">
                <div class="action__menu-box-title">{{ translation.menu }}</div>
                <ul>
                  <li>
                    <a href="#" @click.prevent="setLogout">{{ translation.logout }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script lang="ts">
// Vue
import { Options, Vue } from "vue-class-component";

// Vendors
import vClickOutside from "click-outside-vue3";

// Store
import store from "@/store";

// Models
import { AppUser } from "@/models/app/user";

// Components
import { ElContainer } from "element-plus";
import { User } from "@element-plus/icons-vue";

// Services
import { postRequest } from "@/services/api/request";
import { getTranslation } from "@/services/app/translation";

@Options({
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    ElContainer,
    User,
  },
})
export default class Header extends Vue {
  isActionMenuBoxUserVisible = false;

  get translation(): any {
    return getTranslation(["hello", "menu", "logout"]);
  }

  get user(): AppUser {
    return store.getters.getUser;
  }

  setIsActionMenuBoxUserVisibleFalse(): void {
    this.isActionMenuBoxUserVisible = false;
  }

  async setLogout(): Promise<void> {
    await postRequest({
      uri: `/authentication/logout`,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then(() => {
      store.dispatch("setLogout");

      this.$router.push({
        name: "HomeIndex",
      });
    });
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/core/_variables.scss";
@import "~@/assets/scss/mixins/_breakpoints.scss";

.header {
  background-color: $white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  z-index: 1020;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    &-logo {
      font-size: 16px;
      font-family: $primary-font;
      font-weight: 700;
      color: $black;

      img {
        height: 32px;
      }
    }

    &-action {
      display: flex;
      align-items: center;

      .action {
        display: flex;
        align-items: center;

        &__user {
          font-size: 14px;
          font-weight: 600;
          color: $black;
        }

        &__menu {
          position: relative;

          &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background-color: $black;
            margin-left: 12px;

            svg {
              width: 16px;
              color: $white;
            }
          }

          &-box {
            position: absolute;
            top: 64px;
            right: 0;
            padding: 24px;
            min-width: 280px;
            max-width: 480px;
            width: auto;
            background-color: $white;
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            display: none;
            z-index: 1060;

            &::before {
              content: "";
              position: absolute;
              top: -5px;
              right: 10px;
              display: block;
              width: 12px;
              height: 12px;
              background: $white;
              box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
              transform: rotate(45deg);
            }

            &--is-visible {
              display: block;
            }

            &-title {
              font-size: 16px;
              font-weight: 700;
              color: $black;
            }

            ul {
              list-style: none;
              margin: 24px 0 0;
              padding: 0;

              li {
                padding-top: 8px;
                padding-bottom: 8px;
                border-bottom: 1px solid $grey-70;

                &:first-child {
                  padding-top: 0;
                }

                &:last-child {
                  padding-bottom: 0;
                  border-bottom: 0;
                }

                a {
                  font-size: 14px;
                  color: $black;

                  &:hover,
                  &:active,
                  &:focus,
                  &:focus:active {
                    color: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
